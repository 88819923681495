<template>
  <div class="booking-table-container">
    <div
      class="header-wrapper"
      :style="{ justifyContent: !isBookingTable ? 'flex-end' : '' }"
    >
      <b-btn
        v-if="isBookingTable"
        variant="tab-orange"
        @click="onCreateEvent('all')"
      >
        Neuen Termin erstellen
      </b-btn>
      <b-input-group>
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="placeholder"
          @input="onSearchTable"
        />
        <b-input-group-append>
          <b-button
            :disabled="!filter"
            @click="onClearFilter"
          >
            Löschen
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="table-box">
      <b-table
        id="adminBookingTableId"
        ref="adminBookingTable"
        :items="items"
        :fields="fields"
        :busy="isLoading"
        bordered
        responsive
        striped
        hover
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template v-slot:head(function)>
          <b-btn
            v-if="isBookingTable"
            variant="tab-orange"
            size="sm"
            @click="onCreateEvent(type)"
          >
            Neu
          </b-btn>
        </template>
        <template #table-busy>
          <div class="text-center text-tab-orange my-4">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:empty>
          <div class="empty-slot">
            <h4>KEINE EREIGNISSE</h4>
          </div>
        </template>
        <template v-slot:cell(recurring)="{ value }">
          <icon-reset v-if="value" />
        </template>
        <template v-slot:cell(bookingDay)="{ value }">
          <p :id="`golfGameAnchor${value}`">
            {{ value | germanDate }}
          </p>
        </template>
        <template v-slot:cell(timeFrom)="{ value }">
          {{ value.slice(0, -3) }}
        </template>
        <template v-slot:cell(timeTo)="{ value }">
          {{ value.slice(0, -3) }}
        </template>
        <template v-slot:cell(teamSize)="{ value }">
          {{ getAdventureGolfTeamSizeSum(value) }}
        </template>
        <template v-slot:cell(displayStatus)="{ value }">
          <icon-check
            v-if="value"
            color="green"
          />
          <icon-cross
            v-else
            color="red"
          />
        </template>
        <template v-slot:cell(createdAt)="{ value }">
          {{ value | germanDate }}
        </template>
        <template v-slot:cell(community)="{ item }">
          <b-form-checkbox
            v-model="item.community"
            name="check-button"
            switch
            size="lg"
            @change="onToggleCommunity(item, $event)"
          />
        </template>
        <template v-slot:cell(function)="{ item }">
          <div class="function-td">
            <button @click="onClickDetail(item)">
              <icon-info />
            </button>
            <button
              v-if="isBookingTable"
              @click="onEdit(item)"
            >
              <icon-pencil />
            </button>
            <button @click="onDelete(item)">
              <icon-trash />
            </button>
          </div>
        </template>
      </b-table>
    </div>
    <div
      v-if="pagination"
      class="pagination-box"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      />
    </div>
  </div>
</template>

<script>
import IconPencil from '@/components/icons/IconPencil';
import IconReset from '@/components/icons/IconReset';
import IconInfo from '@/components/icons/IconInfo';
import IconTrash from '@/components/icons/IconTrash';
import IconCheck from '@/components/icons/IconCheck';
import IconCross from '@/components/icons/IconCross';
import { germanDate } from '@/helpers/filters/germanDate';

export default {
  components: {
    IconPencil,
    IconTrash,
    IconReset,
    IconInfo,
    IconCheck,
    IconCross,
  },
  filters: { germanDate },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Suchen',
    },
  },
  data() {
    return {
      filter: '',
      currentPage: 1,
    };
  },
  computed: {
    isBookingTable() {
      return this.type !== 'User';
    },
    rows() {
      return this.items.length;
    },
    perPage() {
      return this.pagination ? 50 : 0;
    },
  },
  methods: {
    onSearchTable() {
      this.$emit('search', this.filter);
    },
    onCreateEvent(type) {
      this.$emit('create', type);
    },
    onClickDetail(item) {
      this.$emit('click-detail', item);
    },
    onEdit(item) {
      this.$emit('edit', item);
    },
    onDelete(item) {
      this.$emit('delete', item);
    },
    onClearFilter() {
      this.filter = '';
      this.$emit('reload');
    },
    getAdventureGolfTeamSizeSum(sizeString) {
      const sizes = sizeString.split('|');
      let sum = 0;
      sizes.forEach((size, index) => {
        if (index !== 2) {
          sum += parseInt(size);
        } else {
          sum += parseInt(size) * 4;
        }
      });
      return sum;
    },
    onToggleCommunity(item, e) {
      this.$emit('toggle-community', {
        item: item,
        newCommunity: e,
      });
    },
  },
};
</script>