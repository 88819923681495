import { Users } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import { SET_USERS } from '../mutationTypes';

const state = {
  users: [],
};

const mutations = {
  [SET_USERS](stateData, data) {
    stateData.users = data;
  },
};

const actions = {
  async getUsers({ commit }) {
    const response = await Users.getUsers();
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }
      commit(SET_USERS, response.data);
    }
    return response;
  },
};

export const getters = {
  getTableUsers(stateData) {
    const result = stateData.users;
    result.forEach((item) => {
      item.community = item.community === 'yes' ? true : false;
    });
    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
