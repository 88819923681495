import axios from 'axios';
import { Contact } from './Contact';
import { BookingInformation } from './BookingInformation';
import { ActivityInformation } from './ActivityInformation';
import { AdminInformation } from './AdminInformation';
import { AuthenticationInformation } from './AuthenticationInformation';
import { Users } from './Users';
import { loader } from '@/plugins/loader';
import { getLoginUserData } from '@/helpers';
import router from '@/router';

export const BASE_API_URL = 'https://api.arena-golf.hosting.jfnet.de/api/';
// export const BASE_API_URL = 'https://dev-api.arena-golf.hosting.jfnet.de/api/';
export const apiClient = axios.create({
  baseURL: BASE_API_URL,
  transformRequest: [
    // eslint-disable-next-line
        (data, headers) => {
      // Do whatever you want to transform the data
      const formData = new FormData();
      for (const key in data) {
        const value = data[key];
        formData.append(key, value);
      }
      return formData;
    },
  ],
});

export const apiAdventureGolfClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Authorization': getLoginUserData()
      ? `${getLoginUserData().accessToken}`
      : null,
  },
  transformRequest: [
    // eslint-disable-next-line
        (data, headers) => {
      // Do whatever you want to transform the data
      const formData = new FormData();
      for (const key in data) {
        const value = data[key];
        formData.append(key, value);
      }
      return formData;
    },
  ],
});

export const apiAdventureGolfAdmin = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Authorization': `${sessionStorage.getItem('admin')}`,
  },
  transformRequest: [
    // eslint-disable-next-line
        (data, headers) => {
      // Do whatever you want to transform the data
      const formData = new FormData();
      for (const key in data) {
        const value = data[key];
        formData.append(key, value);
      }
      return formData;
    },
  ],
});

export const apiFile = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  transformRequest: [
    // eslint-disable-next-line
        (data, headers) => {
      // Do whatever you want to transform the data
      const formData = new FormData();
      for (const key in data) {
        const value = data[key];
        formData.append(key, value);
      }
      return formData;
    },
  ],
});

export const apiDownloadFile = axios.create({
  baseURL: BASE_API_URL,
  transformRequest: [
    // eslint-disable-next-line
        (data, headers) => {
      // Do whatever you want to transform the data
      const formData = new FormData();
      for (const key in data) {
        const value = data[key];
        formData.append(key, value);
      }
      return formData;
    },
  ],
});

export const apiSimple = axios.create({
  baseURL: BASE_API_URL,
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401
            && (error.response.data.information
                && (error.response.data.information === 'resetPassword'
                    || error.response.data.information.toString().toLowerCase().includes('resetpassword')
                )
            )
    ) {
      router.app.$bvModal.msgBoxOk('Bitte ändern Sie Ihr Passwort, um fortzufahren', {
        title: 'Passwort ändern',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        router.app.$bvModal.show('reset-password-modal');
      });
    } else if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      loader.stop();
      router.app.$bvModal.msgBoxOk('Bitte melden Sie sich erneut an.', {
        title: 'Ein Fehler ist aufgetreten. Bitte loggen Sie sich erneut ein.',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        if (router.history._startLocation.includes('admin')) {
          router.push({ name: 'admin-login' });
        } else {
          router.push({ path: '/sessionout' });
        }
      });
    }
    return Promise.reject(error);
  },
);

apiAdventureGolfClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401
            && (error.response.data.information
                && (error.response.data.information === 'resetPassword'
                    || error.response.data.information.toString().toLowerCase().includes('resetpassword')
                )
            )
    ) {
      router.app.$bvModal.msgBoxOk('Bitte ändern Sie Ihr Passwort, um fortzufahren', {
        title: 'Passwort ändern',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        router.app.$bvModal.show('reset-password-modal');
      });
    } else if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      loader.stop();
      router.app.$bvModal.msgBoxOk('Bitte melden Sie sich erneut an.', {
        title: 'Ein Fehler ist aufgetreten. Bitte loggen Sie sich erneut ein.',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        if (router.history._startLocation.includes('admin')) {
          router.push({ name: 'admin-login' });
        } else {
          router.push({ path: '/sessionout' });
        }
      });
    }
    return Promise.reject(error);
  },
);
apiAdventureGolfAdmin.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401
            && (error.response.data.information
                && (error.response.data.information === 'resetPassword'
                    || error.response.data.information.toString().toLowerCase().includes('resetpassword')
                )
            )
    ) {
      router.app.$bvModal.msgBoxOk('Bitte ändern Sie Ihr Passwort, um fortzufahren', {
        title: 'Passwort ändern',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        router.app.$bvModal.show('reset-password-modal');
      });
    } else if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      loader.stop();
      router.app.$bvModal.msgBoxOk('Bitte melden Sie sich erneut an.', {
        title: 'Ein Fehler ist aufgetreten. Bitte loggen Sie sich erneut ein.',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
      }).then(() => {
        if (router.history._startLocation.includes('admin')) {
          router.push({ name: 'admin-login' });
        } else {
          router.push({ path: '/sessionout' });
        }
      });
    }
    return Promise.reject(error);
  },
);
export {
  Contact,
  BookingInformation,
  ActivityInformation,
  AdminInformation,
  AuthenticationInformation,
  Users,
};