import Vue from 'vue';
import Vuex from 'vuex';
import booking from './modules/booking';
import base from './modules/base';
import live from './modules/live';
import admin from './modules/admin';
import user from './modules/user';
import authentication from './modules/authentication';
import users from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    base,
    booking,
    live,
    admin,
    user,
    authentication,
    users,
  },
});