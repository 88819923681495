import { apiClient, apiSimple } from '@/services';

export const Users = {
  getUsers() {
    return apiClient.get('/users');
  },
  updateUser({ id, community }) {
    return apiSimple.patch(`/user/${id}`, {
      community: community,
    });
  },
  deleteUser(id) {
    return apiClient.delete(`/user/${id}`);
  },
  showBookingHistory({ email }) {
    return apiClient.get('/booking/history', {
      params: {
        email: email,
      },
    });
  },
};